<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn small :to="{ name: 'admin-news' }" elevation="0"
            >GERİ DÖN</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h2>Yeni Haber</h2>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-form ref="form" v-model="formIsValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Başlık"
                      v-model="form.title"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Adres Adı"
                      hint="Türkçe, özel karakter ve boşluk kullanmayınız. Örn; haber-adres-adi"
                      v-model="form.urlCode"
                      required
                      :rules="urlCodeRules"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="9">
                    <v-file-input
                      v-model="form.previewImg"
                      label="Önizleme Resmi"
                      accept=".png, .jpeg, .jpg, .gif, .svg"
                      truncate-length="50"
                      :rules="previewImgRules"
                      :loading="fileLoading"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateCreated"
                          label="Tarih"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.dateCreated"
                        @input="datePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      v-model="form.text"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn
                block
                large
                elevation="0"
                color="secondary"
                @click="submitForm()"
                >KAYDET</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default Vue.extend({
  metaInfo: {
    title: "Yeni Haber"
  },

  components: {
    ckeditor: CKEditor.component
  },

  data: () => {
    return {
      formIsValid: false,

      form: {
        title: "",
        urlCode: "",
        previewImg: null,
        text: "",
        images: [],
        dateCreated: new Date().toISOString().substr(0, 10)
      },

      datePicker: false,

      requiredRule: [v => !!v || "Zorunlu alan"],

      urlCodeRules: [
        v => !!v || "Zorunlu alan",
        v =>
          /^[a-z0-9-]*$/.test(v) || "Geçersiz adres adı. Örn; haber-adres-adi"
      ],

      fileLoading: false,

      previewImgRules: [
        v => !!v || "Zorunlu alan",
        v => !v || v.size < 2097152 || "Dosya boyutu 2 MB'den küçük olmalıdır."
      ],

      previewImgDownloadUrl: "",

      editorConfig: {
        language: "tr",
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "highlight",
          "subscript",
          "superscript",
          "specialCharacters",
          "|",
          "alignment",
          "numberedList",
          "bulletedList",
          "outdent",
          "indent",
          "blockQuote",
          "|",
          "removeFormat",
          "link",
          "insertTable",
          "mediaEmbed",
          "imageInsert",
          "htmlEmbed",
          "code"
        ],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    };
  },

  computed: {
    editor: () => {
      return ClassicEditor;
    }
  },

  methods: {
    async uploadPreviewImg() {
      try {
        const img = this.form.previewImg;
        const fileExt = img.name.substring(
          img.name.lastIndexOf("."),
          img.name.length
        );
        const fileName = this.form.urlCode + fileExt;

        const uploadTask = await firebase
          .storage()
          .ref()
          .child(`/news-images/${fileName}`)
          .put(img);

        this.fileLoading = false;

        this.previewImgDownloadUrl = await uploadTask.ref.getDownloadURL();
      } catch (err) {
        this.fileLoading = false;

        this.$notify({
          title: "Hata!",
          text: `Ön izleme resmi yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
          type: "error"
        });

        console.error(
          `Ön izleme resmi yüklenemedi. ${err.code} ${err.message}`
        );
      }
    },

    async submitForm() {
      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      try {
        // Ön izleme resmini yükle
        await this.uploadPreviewImg();

        const news = {
          title: this.form.title,
          urlCode: this.form.urlCode,
          previewImg: this.previewImgDownloadUrl,
          text: this.form.text,
          dateCreated: new Date(this.form.dateCreated),
          disabled: false,
          images: []
        };

        this.$notify({
          type: "warning",
          title: "Lütfen bekleyin!",
          text: "Haber oluşturuluyor..."
        });

        console.log(news);

        await firebase
          .firestore()
          .collection("/apps/kahev-org/news")
          .add(news);

        this.$router.push({
          name: "admin-news"
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Haber oluşturulamadı."
        });

        console.error(err);
      }
    }
  }
});
</script>
<style lang="scss" scoped></style>
